<template>
  <a-modal title="请编辑当前节点的相关信息" :visible="visible" @ok="handleOk" :width="650" @cancel="handleCancel">
    <a-form :form="form" @submit="handleSubmit">
      <a-form-item label="节点名称：">
        <a-input v-decorator="['name', { rules: [{ required: true, message: '节点名称!' }] }]" />
      </a-form-item>
      <a-form-item label="选择节点审批人：">
        <a-input v-show="false" v-decorator="['person', { rules: [{ required: true, message: '选择节点审批人!' }] }]" />
        <div class="list_content" style="text-align: left; padding-left: 8px">
          <a-tag
            class="tags flex0"
            closable
            color="blue"
            v-for="(o, i) in personList"
            :key="'ngdw_list' + i"
            @close.prevent="personList.splice(i, 1)"
          >
            <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
          </a-tag>
          <a-button slot="suffix" icon="user" @click="showSelctDialog('personList', 2, null, all_list)"></a-button>
          <!-- <div class="list_btn">
            <a-button slot="suffix" icon="user" @click="showSelctDialog('personList', 1)"></a-button>
          </div> -->
        </div>
      </a-form-item>
      <a-form-item label="会签关系：" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-radio-group
          v-decorator="['countersign', { initialValue: 1, rules: [{ required: true, message: '会签关系：!' }] }]"
        >
          <a-radio :style="radioStyle" :value="1">
            会签（多个节点操作者必须全部处理并提交后才能进入下一个节点）
          </a-radio>
          <a-radio :style="radioStyle" :value="2"> 非会签（多个节点操作者中的任意一人处理即可进入下一个节点） </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
    <selectPersonDialog ref="selectPersonDialog" @selectTodoMsg="selectTodoMsg" />
  </a-modal>
</template>
<script>
import { selectPerson } from '@/mixins/index'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
export default {
  components: {
    OpenData,
    selectPersonDialog,
  },
  mixins: [selectPerson],
  data() {
    return {
      visible: false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      nodeMess: {},
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      personList: [],
      all_list: [],
    }
  },
  watch: {
    personList(val) {
      if (val && val.length) {
        this.form.setFieldsValue({
          person: JSON.stringify(val),
        })
      } else {
        this.form.setFieldsValue({
          person: '',
        })
      }
    },
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          this.$emit('selectPersons', {
            nodeMess: this.nodeMess,
            values,
          })
          this.nodeMess = {}
          this.form.resetFields()
          this.visible = false
        }
      })
    },
    open(nodeMess) {
      this.nodeMess = nodeMess
      const { el, allList } = nodeMess
      this.all_list = allList || []
      this.visible = true
      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: el.name,
          countersign: el.countersign || 1,
        })
        this.personList = el.val
      })
    },
    handleOk() {
      this.handleSubmit()
    },
    handleCancel() {
      this.nodeMess = {}
      this.form.resetFields()
      this.visible = false
    },
  },
}
</script>
<style lang="less" scoped>
.tags {
  line-height: 32px;
}
.list_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .my-item {
    width: 40%;
    margin: 0 5px;
  }
  .list_btn {
    width: 100%;
  }
}
</style>
